<template>
  <div class="gameboard">
      <PlayGround />
  </div>
</template>

<script>
import PlayGround from '@/components/PlayGround'

export default {
  name: 'App',
  components: {
    PlayGround,
  }
}
</script>

<style scoped>
.gameboard {
  width: 30%;
  height: 80%;
}
</style>
